import dayjs from 'dayjs';
import React, { FC, useEffect, useRef } from 'react';
import { Form, Input, InputNumber, Switch, Button, Space, Upload, DatePicker, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

interface FieldSettings {
  type?: string; // Type of the field (e.g. input, switch, select)
  editable?: boolean; // Determines if the field is editable or read-only
  hidden?: boolean; // Determines if the field is hidden or visible
  label?: string; // Custom label for the field
  rules?: Array<any>; // Validation rules for the field
}

interface ObjectFormProps {
  data: Record<string, any>; // The raw object data
  settings?: Record<string, FieldSettings>; // Optional settings for customizing each field
  loading?: boolean; // Loading state for the form
  onFinish: (values: any) => void; // Callback for form submission
}

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const parseLabel = (key: string): string => {
  return key
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const normFile = (e: any) => {
  console.log('Upload event:', e);
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const renderField = (fieldType: string, editable: boolean, value: any, options: any) => {
  if (!editable)
    return <span className="ant-form-text">{value}</span>;

  switch (fieldType) {
    case 'input':
      return <Input />;
    case 'number':
      return <InputNumber />;
    case 'datetime': 
      return <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />;
    case 'switch':
      return <Switch />;
    case 'select':
      return (
        <Select>
          {options.map((option: any) => <Select.Option key={option.value} value={option.value}>{option.label}</Select.Option>)}
        </Select>
      );
    default:
      return <Input />; // Fallback to Input if no specific type is provided
  }
};

const isPrimitive = (value: any) => {
  return value !== Object(value); // Returns true for strings, numbers, booleans, etc.
};

const filterPrimitiveFields = (data: Record<string, any> | null | undefined) => {
  // Check if data is null or undefined and return an empty object in that case
  if (!data || typeof data !== 'object') {
    return {};
  }

  return Object.keys(data).reduce((acc, key) => {
    if (isPrimitive(data[key])) {
      acc[key] = data[key];
    }
    return acc;
  }, {} as Record<string, any>);
};

const ObjectForm: FC<ObjectFormProps> = ({ data, settings = {}, loading, onFinish }) => {
  const [form] = Form.useForm();
  const initialDataRef = useRef<Record<string, any>>({}); // To store the initial data for comparison

  useEffect(() => {
    const filteredData = filterPrimitiveFields(data);
  
    // Iterate over settings to check if any field is of type 'picture'
    Object.keys(settings).forEach((key) => {
      const fieldSettings = settings[key];
      
      if (fieldSettings.type === 'picture' && data[key]) {
        filteredData[key] = [{
          uid: '-1',
          name: `${key}.png`,
          status: 'done',
          url: data[key]
        }];
      }
      else if (fieldSettings.type === 'datetime' && data[key]) {
        filteredData[key] = dayjs(data[key]);
      }
    });

    // Store the initial data for future comparison
    initialDataRef.current = { ...filteredData };
  
    form.setFieldsValue(filteredData);
  }, [data, form, settings]);  

  const renderFormItem = (key: string, value: any) => {
    if (!isPrimitive(value)) {
      return null;
    }

    const fieldSettings = settings[key] || {};
    const label = fieldSettings.label || parseLabel(key);
    
    const editable = fieldSettings.editable ?? false;
    const hidden = fieldSettings.hidden ?? false;
    const fieldType = fieldSettings.type || 'input'; // Default to input if type is not specified
    const options = fieldSettings.rules?.[0]?.options || [];

    if (hidden)
      return null;

    if (fieldType === 'picture') {
      return (
        <Form.Item key={key} name={key} label={label} valuePropName="fileList" getValueFromEvent={normFile}>
          <Upload /* action="/upload.do"  */listType="picture-card" maxCount={1}>
            <button style={{ border: 0, background: 'none' }} type="button">
              <UploadOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>
        </Form.Item>
      )
    }
  
    return (
      <Form.Item key={key} name={key} label={label} rules={fieldSettings.rules || []}>
        {renderField(fieldType, editable, value, options)}
      </Form.Item>
    );
  };

  // Helper function to combine and order fields based on settings and data keys
  const getOrderedFields = () => {
    const settingsKeys = Object.keys(settings);
    const dataKeys = Object.keys(data);

    // Create an ordered array by prioritizing settings keys, then appending remaining data keys
    const orderedKeys = [
      ...settingsKeys,
      ...dataKeys.filter((key) => !settingsKeys.includes(key)),
    ];

    return orderedKeys;
  };

  // Helper function to compare form values and return only modified fields
  const getModifiedFields = (values: Record<string, any>) => {
    const modifiedFields: Record<string, any> = {};

    Object.keys(values).forEach((key) => {
      if (JSON.stringify(values[key]) !== JSON.stringify(initialDataRef.current[key])) {
        modifiedFields[key] = values[key]; // Only include fields that have changed
      }
    });

    return modifiedFields;
  };

  // Handle form submission
  const handleFinish = (values: any) => {
    const modifiedFields = getModifiedFields(values);
    onFinish(modifiedFields); // Pass only modified fields to the callback
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form form={form} {...layout} onFinish={handleFinish} style={{ maxWidth: 600 }}>
      {getOrderedFields().map((key) => renderFormItem(key, data[key]))}

      <Form.Item {...tailLayout}>
        <Space align='end'>
          {/* <Button htmlType="button" onClick={onReset}>
            Reset
          </Button> */}
          <Button type="primary" disabled={loading} htmlType="submit">
            {loading ? 'Saving...' : 'Save'}
          </Button>
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ObjectForm;
