export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_DOMAINS_REQUEST",
  SUCCESS: "ADMIN_FETCH_DOMAINS_SUCCESS",
  FAILURE: "ADMIN_FETCH_DOMAINS_FAILURE",
}

export interface IAdminDomain {
  id: string;
  created_at: string,
  updated_at: string,
  name: string,
  order: number,
  description: string,
  icon: string | null,
  code: string,
  type: string,
  visible: boolean,
  arootah_only: boolean,
  legacy_domain: string | null
}

export interface IAdminDomainsState {
  data: IAdminDomain[];
  loading: boolean;
}
