import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import moment from 'moment';

import { Button, Segmented, Space, Switch, Table, Typography, Input, Row, Col } from 'antd';
import type { TableColumnsType } from 'antd';

import { fetchUsers } from 'Redux/Admin/Users/action';

import { RootState } from "Redux/store";
import { IAdminUser } from 'Redux/Admin/User/type';

import { ADMIN_PATH } from 'utils/routes';

const columns: TableColumnsType<IAdminUser> = [
  {
    title: 'Email',
    dataIndex: 'email',
    render: (text, record) => <b><Link to={`${ADMIN_PATH}/users/${record.id}`}>{text}</Link></b>,
    width: 320,
  },
  {
    title: 'Full Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Joined',
    dataIndex: 'date_joined',
    render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    width: 220
  },
  /* {
    title: 'Is Verified',
    dataIndex: 'is_verified',
    width: 120
  },
  {
    title: 'Is Superuser',
    dataIndex: 'is_superuser',
    width: 120
  }, */
];

const { Search } = Input;

const Users = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const searchValue = params.get('search');
  const pageValue = parseInt(params.get('page') || '1');
  const pageSize = parseInt(params.get('size') || '50');

  const { data, loading } = useSelector((state: RootState) => state.fetchUsersReducer);

  useEffect(() => {
    dispatch(fetchUsers({ search: searchValue, offset: (pageValue - 1) * 50, limit: 50 }, () => {}));
  }, [searchValue, pageValue]);

  const handleSearch = (value: string) => {
    navigate(`?search=${value}&page=1&size=${pageSize}`);
  }

  const handleChange = (pagination: any) => {
    let url = `?page=${pagination.current}&size=${pagination.pageSize}`;

    if (searchValue) {
      url = `?search=${searchValue}&page=${pagination.current}&size=${pagination.pageSize}`;
    }

    navigate(url);
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Row>
        <Col span={12}>
          <Space direction="vertical" style={{ width: '100%', marginBottom: 12 }}>
            <Search defaultValue={searchValue || ''} placeholder="input search text" onSearch={handleSearch} enterButton={true} style={{ maxWidth: 320 }}/>
          </Space>

          <Table
            rowKey={record => record.id}
            style={{ flex: 1 }}
            columns={columns}
            dataSource={data.results}
            pagination={{ pageSize, total: data.count, current: pageValue }}
            loading={loading}
            scroll={{ y: 'calc(100vh - 64px - 48px - 48px - 48px - 48px)' }}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Users;
