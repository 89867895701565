export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_GM_USER_REQUEST",
  SUCCESS: "ADMIN_FETCH_GM_USER_SUCCESS",
  FAILURE: "ADMIN_FETCH_GM_USER_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_USER_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_USER_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_USER_FAILURE",
}

export interface IAdminGmUser {
  id: string,
  last_login: null | string,
  is_superuser: boolean,
  first_name: string,
  last_name: string,
  is_staff: boolean,
  is_active: boolean,
  date_joined: null | string,
  username: string,
  public_id: string,
  email: string,
  type: string,
  name: string,
  is_guest: boolean,
  ip: null | string,
  tokens: number,
  activation_key: string,
  otp: string,
  terms_accepted: boolean,
  progress_updated_at: null | string,
  groups: any[],
  user_permissions: any[],
  coaches: any[]
}

export interface IAdminGmUserState {
  data: IAdminGmUser | null;
  loading: boolean;
  loading_update: boolean;
}
