import Notify from "utils/notify";

import { adminFetchDomains } from "api";

import { ActionTypes } from "./type";

export const fetchDomains = (payload: any, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchDomains(payload);

    if (Array.isArray(data)) {
      dispatch({
        type: ActionTypes.SUCCESS,
        payload: data
      });
    }
    else {
      dispatch({
        type: ActionTypes.FAILURE,
        payload: data
      });

      Notify.error(data, true);
    }

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
