const location = window.location.href;

const apiProdURL = 'auth.arootah.com';
const apiStagingURL = 'compensation-tool-3752-staging.botics.co';
const apiDevURL = 'dev.auth.arootah.com';

let apiURL = apiProdURL;

// Array of dev/staging urls
const validURLs = [
  'compensation-tool-3752-staging.botics.co',
  'dev.auth.arootah.com',
  'localhost',
  '127.0.0.1'
];

// Checking if current url is a valid url
if (process.env.NODE_ENV === 'stage' || validURLs.some(url => location.includes(url))) {
  apiURL = apiStagingURL;
}

// Array of dev/staging urls
const validDevURLs = [
  'dev.auth.arootah.com',
  'localhost',
  '127.0.0.1'
];

if (process.env.NODE_ENV === 'development' || validDevURLs.some(url => location.includes(url))) {
  apiURL = apiDevURL;
}

console.log(`${apiURL === apiProdURL && process.env.NODE_ENV === 'production' ? '🟢' : '🟡'} API:`, apiURL);

export const ROOT_API_URL = `https://${apiURL}/api/v1/`;

export const DM_API_URL = `https://dev.pp.arootah.com/api/v1/`;
export const GM_API_URL = `https://dev.gm.arootah.com/api/v1/`;
