import { BaseService } from "./base";
import { DMServices } from "./dm";
import { GMServices } from "./gm";

import { buildUrl } from "utils/utility";

export const checkAuth = () => BaseService.get("/user/check_auth/", false);
export const removeAuth = () => BaseService.get("user/remove_auth/");
export const register = (data: any) => BaseService.post("user/", data, false);
export const login = (data: any) => BaseService.post("user/login/", data, false);
export const logout = () => BaseService.get("user/logout/");
export const passwordResetLink = (data: any) => BaseService.post("user/send_reset_password_email/", data, false);
export const resetPassword = (data: any, token: string) => BaseService.post("user/reset_password/", data, true, token);
export const verifyOtp = (data: any) => BaseService.post("user/verify_otp/", data, false);
export const resendOtp = (data: any) => BaseService.post("user/resend_otp/", data, false);

// Admin
export const adminFetchUsers = async (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/users/', filterParams);
  const response = await BaseService.get(url, true);
  
  // Extract the headers, including the "Count" property
  const { data, headers } = response;

  // If you need the "Count" header, you can access it like this:
  const count = headers['count'];

  // Return both the data and any additional info you need
  return {
    data,
    count, // return "Count" or any other header parameter you need
  };
};

export const adminFetchUser = (payload: string) => BaseService.get(`custom-admin/users/${payload}/`, true);
export const adminUpdateUser = (payload: FormData, id: string) => BaseService.patch(`custom-admin/users/${id}/`, payload, true);
export const adminDeleteUser = (payload: string) => BaseService.remove(`custom-admin/users/${payload}/`, true);

export const adminFetchDmUser = (payload: string) => DMServices.get(`custom-admin/users/?public_id=${payload}`, true);
export const adminUpdateDmUser = (payload: FormData, id: string) => DMServices.patch(`custom-admin/users/${id}/`, payload, true);

export const adminFetchGmUser = (payload: string) => GMServices.get(`custom-admin/users/?public_id=${payload}`, true);
export const adminUpdateGmUser = (payload: FormData, id: string) => GMServices.patch(`custom-admin/users/${id}/`, payload, true);

// Admin
export const adminFetchDomains = (filterParams: any = {}) => {
  const url = buildUrl('custom-admin/system-domains/', filterParams);
  return GMServices.get(url, true);
}
