import { combineReducers } from "redux";

// @ts-ignore
import tokenReducer from './Token/reducer';
// @ts-ignore
import registerUserReducer from "./Register/reducer";
// @ts-ignore
import loginUserReducer from "./Login/reducer";
// @ts-ignore
import logoutUserReducer from "./Logout/reducer";

// @ts-ignore
import resetPasswordReducer from "./ResetPasword/reducer";
// @ts-ignore
import resetPasswordLinkReducer from "./PasswordResetLink/reducer";
// @ts-ignore
import verifyOtpReducer from "./VerifyOtp/reducer"
// @ts-ignore
import resendOtpReducer from "./ResendOtp/reducer"
// @ts-ignore
import userDetailReducer from "./User/reducer";

import fetchUsersReducer from "./Admin/Users/reducer";
import fetchUserReducer from "./Admin/User/reducer";
import fetchDmUserReducer from "./Admin/DmUser/reducer";
import fetchGmUserReducer from "./Admin/GmUser/reducer";
import fetchDomains from "./Admin/GoalsManager/Domains/reducer";

export default combineReducers({
  tokenReducer,
  registerUserReducer,
  loginUserReducer,
  logoutUserReducer,

  resetPasswordReducer,
  resetPasswordLinkReducer,

  userDetailReducer,
  verifyOtpReducer,
  resendOtpReducer,

  // Admin
  fetchUsersReducer,
  fetchUserReducer,
  fetchDmUserReducer,
  fetchGmUserReducer,
  fetchDomains
});