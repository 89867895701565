import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, Link } from 'react-router-dom';

import { Button, Segmented, Space, Switch, Table, Typography, Input, Row, Col } from 'antd';
import type { TableColumnsType } from 'antd';

import { fetchUsers } from 'Redux/Admin/Users/action';

import { RootState } from "Redux/store";
import { IAdminUser } from 'Redux/Admin/User/type';

import { ADMIN_PATH } from 'utils/routes';
import { fetchDomains } from 'Redux/Admin/GoalsManager/Domains/action';

const columns: TableColumnsType<IAdminUser> = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 220
  },
  {
    title: 'Order',
    dataIndex: 'order',
    width: 120
  },
  {
    title: 'Color',
    dataIndex: 'code',
    width: 120
  }
];

const Domains = () => {
  const dispatch = useDispatch();

  const { data, loading } = useSelector(
    (state: RootState) => state.fetchDomains
  );

  useEffect(() => {
    dispatch(fetchDomains({}));
  }, []);
  
  return (
    <Row>
      {/* <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}> */}
        <Col span={12}>

      <Table
        rowKey={record => record.id}
        style={{ flex: 1 }}
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 50 }}
        loading={loading}
        scroll={{ y: 'calc(100vh - 64px - 48px - 48px - 48px - 48px)' }}
      />
      </Col>
      {/* </div> */}
    </Row>
  );
}

export default Domains;
