import {
  Empty,
  Spin,
  Tabs,
  TabsProps
} from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { useNavigate, useParams } from "react-router-dom";

import ObjectForm from "components/Admin/ObjectForm";

import { updateUser } from "Redux/Admin/DmUser/action";

import { RootState } from "Redux/store";
// import { ADMIN_PATH } from "utils/routes";

const settings = {
  id: { hidden: true },
  password: { hidden: true },

  public_id: { label: "Public ID" },

  first_name: { editable: true, type: 'input' },
  last_name: { editable: true, type: 'input' },
  name: { editable: true, type: 'input' },

  username: { editable: true, type: 'input', rules: [{ required: true }] },
  email: { editable: true, type: 'input', rules: [{ type: 'email', required: true }] },
  phone: { editable: true, type: 'input' },

  profile_picture: { editable: true, type: 'picture' },

  is_coach: { editable: true, type: 'switch' },
  is_superuser: { editable: true, type: 'switch' },
  is_staff: { editable: true, type: 'switch' },
  is_active: { editable: true, type: 'switch' },
  verified_email: { editable: true, type: 'switch' },

  user_time_zone: { editable: true, type: 'input' },

  date_joined: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  last_login: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
};

const DecisionsManager = () => {
  // const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { data, loading, loading_update } = useSelector(
    (state: RootState) => state.fetchDmUserReducer
  );

  const onFinish = (values: any) => {
    console.log('[onFinish]', values);

    // @ts-ignore
    if (data.id) {
      // @ts-ignore
      dispatch(updateUser(values, data.id, handleAfterUpdate));
    }
  };

  const handleAfterUpdate = () => {
    // navigate(`${ADMIN_PATH}/users`);
  };

  if (loading) {
    return <Spin tip="Loading" />;
  }

  if (!data) {
    return <Empty />
  }

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'User Info',
      children: (
        <ObjectForm
          data={data}
          settings={settings}
          loading={loading_update}
          onFinish={onFinish}
        />
      ),
    },
    {
      key: '2',
      label: 'Master Plans',
      children: "Master Plans",
      disabled: true
    }
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition={"top"}
      items={tabItems}
    />
  )
}

export default DecisionsManager;
