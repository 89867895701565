import { useLocation, Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';

import type { MenuProps } from 'antd';
import { ADMIN_PATH } from 'utils/routes';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    key: 'general',
    label: 'General',
    type: 'group',
    children: [
      { key: 'users', label: <Link to={`${ADMIN_PATH}/users`}>Users</Link> },
    ],
  },
  {
    type: 'divider',
  },
  {
    key: 'system',
    label: 'System',
    type: 'group',
    children: [
      { key: 'dm', label: 'Decision Manager', disabled: true },
      {
        key: 'goals',
        label: 'Goals Manager',
        disabled: true,
        children: [
          { key: 'domains', label: <Link to={`${ADMIN_PATH}/goals/domains`}>Domains</Link> },
          { key: 'instructions', label: 'Instructions' },
          { key: 'feedback', label: 'Feedback' },
        ],
      },
    ],
  },
];

const Sidebar = () => {
  const { pathname } = useLocation();

  const activeKey = pathname.split('/')[2];
  const activeKey2 = pathname.split('/')[3];

  return (
    <Sider width={200}>
      <Menu mode="inline" selectedKeys={[activeKey, activeKey2]} items={items} style={{ height: '100%', minHeight: `calc(100vh - 64px)` }} />
    </Sider>
  );
}

export default Sidebar;
