import { IAdminDomainsState, ActionTypes } from './type';

const initial = {
  data: [],
  loading: false,
};

export default (state: IAdminDomainsState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        data: action.payload,
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
