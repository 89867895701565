import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import store, { RootState } from "Redux/store";
// @ts-ignore
import { CheckAuth } from "Redux/Token/action";

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return token;
};

export function getAppBaseURL() {
  try {
    const parsedURL = new URL(window.location.href);
    return parsedURL.hostname;
  } catch (e) {
    return null;
  }
}

export function getToken() {
  const state = store.getState();
  const token = state.tokenReducer.token || null;

  return token;
}

export function useToken() {
  const dispatch = useDispatch();
  const { token, success, loading } = useSelector((state: RootState) => state.tokenReducer);

  useEffect(() => {
    if (!success && !token) {
      dispatch(CheckAuth());
    }
  }, []);

  return {
    token,
    success,
    loading
  }
}

export function useUser() {
  const { userDetail } = useSelector((state: RootState) => state.userDetailReducer);

  return {
    userDetail
  }
}

export function useAllocatorAccess() {
  const { app_access } = useSelector((state: RootState) => state.userDetailReducer.userDetail);

  if (app_access) {
    if (app_access?.allocator_tool && app_access?.allocator_tool?.length > 0 && app_access?.allocator_tool.includes("write")) {
      return true;
    }
  }

  return false;
}

export function buildUrl(url: string, params: any) {
  const queryString = Object.keys(params)
    .filter(key => params[key] !== '' && params[key] !== undefined && params[key] !== null)
    .map(key => {
        const value = params[key];
        if (Array.isArray(value)) {
          // Join array values with a comma
          return `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`;
        } else {
          // Directly encode the string representation of the value
          return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
        }
    })
    .join('&');
  return url + (queryString ? `?${queryString}` : '');
};
