import {
  Empty,
  Spin
} from "antd";
import ObjectForm from "components/Admin/ObjectForm";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchDMUser } from "Redux/Admin/DmUser/action";

import { fetchUser, updateUser } from "Redux/Admin/User/action";

import { RootState } from "Redux/store";
import { ADMIN_PATH } from "utils/routes";

const settings = {
  id: { hidden: true },
  uuid: { label: "UUID" },

  password: { hidden: true },
  password_reset_required: { hidden: true },

  first_name: { editable: true, type: 'input' },
  last_name: { editable: true, type: 'input' },
  username: { editable: true, type: 'input', rules: [{ required: true }] },

  email: { editable: true, type: 'input', rules: [{ type: 'email', required: true }] },
  phone: { editable: true, type: 'input' },
  name: { editable: true, type: 'input' },

  profile_picture: { editable: true, type: 'picture' },
  external_profile_picture: { editable: true, type: 'picture' },

  is_superuser: { editable: true, type: 'switch' },
  is_staff: { editable: true, type: 'switch' },
  is_active: { editable: true, type: 'switch' },
  is_verified: { editable: true, type: 'switch' },

  date_joined: { editable: true, type: 'datetime', rules: [{ type: 'object' as const/* , required: true */ }] },
  last_login: { editable: true, type: 'datetime', rules: [{ type: 'object' as const/* , required: true */ }] },

  verify_secret_key: { hidden: true }
};

const General = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const { data, loading } = useSelector(
    (state: RootState) => state.fetchUserReducer
  );

  const onFinish = (values: any) => {
    console.log('[onFinish]', values);

    if (id) {
      dispatch(updateUser(values, id, handleAfterUpdate));
    }
  };

  const handleAfterUpdate = () => {
    // navigate(`${ADMIN_PATH}/users`);
  };

  if (loading) {
    return <Spin tip="Loading" />;
  }

  if (!data) {
    return <Empty />
  }

  return (
    <ObjectForm
      data={data}
      settings={settings}
      loading={false}
      onFinish={onFinish}
    />
  );
}

export default General;
