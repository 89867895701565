import { IAdminUserState, ActionTypes } from './type';

const initial = {
  data: null,
  loading: false,
  loading_update: false
};

export default (state: IAdminUserState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        data: action.payload,
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        loading_update: true
      };
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading_update: false
      };
    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        loading_update: false
      };

    default:
      return state;
  }
}
