import { IAdminDmUserState, ActionTypes } from './type';

const initial = {
  data: null,
  loading: false,
  loading_update: false
};

export default (state: IAdminDmUserState = initial, action: any) => {
  switch (action.type) {
    case ActionTypes.REQUEST:
      return {
        ...state,
        loading: true
      };
    case ActionTypes.SUCCESS:
      return {
        data: Array.isArray(action.payload.results) && action.payload.results.length > 0 ? action.payload.results[0] : null,
        loading: false
      };
    case ActionTypes.FAILURE:
      return {
        ...state,
        loading: false
      };

    case ActionTypes.UPDATE_REQUEST:
      return {
        ...state,
        loading_update: true
      };
    case ActionTypes.UPDATE_SUCCESS:
      return {
        ...state,
        loading_update: false
      };
    case ActionTypes.UPDATE_FAILURE:
      return {
        ...state,
        loading_update: false
      };

    default:
      return state;
  }
}
