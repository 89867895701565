import axios from "axios";
import { DM_API_URL } from "Config/config";

// @ts-ignore
import { getToken } from "utils/utility";

const onError = async (error: any) => {
  return Promise.reject(error);
};

const request = async (options: any, isSecure: boolean) => {
  let baseURL = DM_API_URL;
  const token = getToken();

  let headers = {};

  if (isSecure && (token || options.token)) {
    // @ts-ignore
    headers["Authorization"] = `Token ${(token || options.token)} `;
  }

  const client = axios.create({
    baseURL,
    headers: { ...headers },
  });

  return client(options).catch(onError);
};

export class DMServices {
  static get = (url: string, isSecure = true) => {
    return request(
      {
        url,
        method: "GET",
      },
      isSecure
    );
  };

  static patch = (url: string, data: any, isSecure = true) => {
    return request(
      {
        url,
        method: "PATCH",
        data,
      },
      isSecure
    );
  };
}
