import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import styled from 'styled-components';

// Auth
import Landing from "screens/AuthScenes/Landing";
import Verification from "screens/AuthScenes/Verification";

import SignIn from 'screens/AuthScenes/SignIn';
import SignUp from 'screens/AuthScenes/SignUp';

import ForgotPassword from "screens/AuthScenes/ForgotPassword";
import ResetPassword from "screens/AuthScenes/ResetPassword";
import ForgotPasswordSuccess from "screens/AuthScenes/ForgotPasswordSuccess";

import AdminScenes from 'screens/AdminScenes';
import AdminUsers from 'screens/AdminScenes/Users';
import AdminUser from 'screens/AdminScenes/User';

import Domains from 'screens/AdminScenes/GoalsManager/Domains';

import { Protected, Guest } from "Hooks/ProtectedRoutes";
import { CheckAuth } from "Redux/Token/action";

import {
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  FORGOT_PASSWORD_PATH,
  FORGOT_PASSWORD_SUCCESS_PATH,
  RESET_PASSWORD_PATH,
  ADMIN_PATH,
  INDEX_PATH,
  OTP_PATH
} from "utils/routes";

const AppRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    function handleWindowFocus() {
      dispatch(CheckAuth({ silent: true }));
    }

    if (process.env.NODE_ENV !== 'development')
      window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);

  return (
    <Container>
      <Routes>
        {/* <Route index element={<Guest><Navigate to={SIGN_IN_PATH} replace={true} /></Guest>} /> */}

        <Route path={INDEX_PATH} exact element={
          <Protected>
            <Landing />
          </Protected>
        } />

        <Route path={OTP_PATH} exact element={
          <Guest>
            <Verification />
          </Guest>
        } />

        <Route path={SIGN_IN_PATH} exact element={
          <Guest>
            <SignIn />
          </Guest>
        } />

        <Route path={SIGN_UP_PATH} exact element={
          <Guest>
            <SignUp />
          </Guest>
        } />

        <Route path={FORGOT_PASSWORD_PATH} exact element={
          <Guest>
            <ForgotPassword />
          </Guest>
        } />

        <Route path={FORGOT_PASSWORD_SUCCESS_PATH} exact element={
          <Guest>
            <ForgotPasswordSuccess />
          </Guest>
        } />

        <Route path={RESET_PASSWORD_PATH} exact element={
          <Guest>
            <ResetPassword />
          </Guest>
        } />

        <Route path={`${ADMIN_PATH}/*`} element={
          <Protected>
            <AdminScenes />
          </Protected>
        }>
          <Route path={'users'} element={<AdminUsers />} />
          <Route path={'users/:id/*'} element={<AdminUser />} />

          <Route path={'goals/domains'} element={<Domains />} />
        </Route>
      </Routes>
    </Container>
  );
};

const Container = styled.div``;

export default AppRoutes;
