export const settings = {
  id: { hidden: true },
  password: { hidden: true },

  public_id: { label: "Public ID" },

  first_name: { editable: true, type: 'input' },
  last_name: { editable: true, type: 'input' },
  name: { editable: true, type: 'input' },

  username: { editable: true, type: 'input', rules: [{ required: true }] },
  email: { editable: true, type: 'input', rules: [{ type: 'email', required: true }] },

  type: { editable: true, type: 'select', rules: [{ required: true, options: [
    { value: 'Client', label: 'Client' },
    { value: 'Coach', label: 'Coach' },
  ] }] },
  tokens: { editable: true, type: 'number', rules: [{ required: true }] },

  is_superuser: { editable: true, type: 'switch' },
  is_staff: { editable: true, type: 'switch' },
  is_active: { editable: true, type: 'switch' },
  is_guest: { editable: true, type: 'switch' },
  terms_accepted: { editable: true, type: 'switch' },

  date_joined: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  last_login: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },
  progress_updated_at: { editable: true, type: 'datetime', rules: [{ type: 'object' as const }] },

  ip: { label: 'IP' },
  activation_key: {},
  otp: { label: 'OTP' },
};
