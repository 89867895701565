export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_USER_REQUEST",
  SUCCESS: "ADMIN_FETCH_USER_SUCCESS",
  FAILURE: "ADMIN_FETCH_USER_FAILURE",

  UPDATE_REQUEST: "ADMIN_UPDATE_USER_REQUEST",
  UPDATE_SUCCESS: "ADMIN_UPDATE_USER_SUCCESS",
  UPDATE_FAILURE: "ADMIN_UPDATE_USER_FAILURE",
}

export interface IAdminExternalAccounts {
  decision_and_project: number;
}

export interface IAdminUser {
  id: string;
  password: string,
  last_login: null | string,
  is_superuser: boolean,
  first_name: string,
  last_name: string,
  is_staff: boolean,
  is_active: boolean,
  date_joined: null | string,
  uuid: string,
  email: string,
  name: string,
  username: string,
  phone: string | null,
  profile_picture: null | string,
  external_profile_picture: null | string,
  password_reset_required: boolean,
  external_accounts: IAdminExternalAccounts[],
  is_verified: boolean,
  verify_secret_key: null,
  groups: any[],
  user_permissions: any[]
}

export interface IAdminUserState {
  data: IAdminUser | null;
  loading: boolean;
}
