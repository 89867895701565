import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Typography, Button, ConfigProvider, Layout, Menu, theme } from 'antd';
import { SunFilled, MoonFilled, UploadOutlined, UserOutlined, VideoCameraOutlined } from '@ant-design/icons';

import type { MenuProps } from 'antd';

import Sidebar from 'components/Admin/Sidebar';

const { Header, Content, Footer, Sider } = Layout;
const { Title } = Typography;

const AdminDashboard = () => {
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const {
    token: { colorBgContainer, borderRadiusLG },
    // @ts-ignore
  } = theme.useToken();

  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const handleClick = () => {
    setIsDarkMode((previousValue) => !previousValue);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
      }}>
      <Layout>
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0 16px' }}>
          <Title level={4} style={{ color: '#fff', marginBottom: 0 }}>Admin Dashboard</Title>

          <Button
            shape="circle"
            variant="text"
            icon={isDarkMode ? <SunFilled /> : <MoonFilled />}
            title={isDarkMode ? 'Switch to light mode' : 'Switch to dark mode'}
            onClick={handleClick}
          />
        </Header>

        <Content>
          <Layout
            style={{ minHeight: `calc(100vh - 64px)` }}
          >
            <Sidebar />

            <Content style={{ padding: '24px', minHeight: 280 }}>
              <Outlet />
            </Content>
          </Layout>
        </Content>

        {/* <Footer style={{ textAlign: 'center' }}>
          Ant Design ©{new Date().getFullYear()} Created by Ant UED
        </Footer> */}
      </Layout>
    </ConfigProvider>
  );
}

export default AdminDashboard;
