import Notify from "utils/notify";

import { adminFetchGmUser, adminUpdateGmUser } from "api";

import { ActionTypes } from "./type";

export const fetchGMUser = (payload: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchGmUser(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });    

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const updateUser = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_REQUEST });

    await adminUpdateGmUser(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: null
    });

    Notify.success('User updated successfully!');

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.UPDATE_FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
