import {
  Empty,
  Spin,
  Tabs,
  TabsProps
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import ObjectForm from "components/Admin/ObjectForm";

import { updateUser } from "Redux/Admin/GmUser/action";

import { RootState } from "Redux/store";

import { settings } from './const';

const UserInfo = () => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { data, loading_update } = useSelector(
    (state: RootState) => state.fetchGmUserReducer
  );

  const onFinish = (values: any) => {
    console.log('[onFinish]', values);

    // @ts-ignore
    if (data.id) {
      // @ts-ignore
      dispatch(updateUser(values, data.id, handleAfterUpdate));
    }
  };

  const handleAfterUpdate = () => {
    // navigate(`${ADMIN_PATH}/users`);
  };

  return (
    <ObjectForm
      data={data}
      settings={settings}
      loading={loading_update}
      onFinish={onFinish}
    />
  );
}

export default UserInfo;
