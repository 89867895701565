import Notify from "utils/notify";

import { adminFetchUser, adminUpdateUser, adminDeleteUser } from "api";

import { ActionTypes } from "./type";

export const fetchUser = (payload: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    const { data } = await adminFetchUser(payload);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: data
    });    

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const updateUser = (payload: any, id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.UPDATE_REQUEST });

    await adminUpdateUser(payload, id);

    dispatch({
      type: ActionTypes.UPDATE_SUCCESS,
      payload: null
    });

    Notify.success('User updated successfully!');

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.UPDATE_FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};

export const deleteUser = (id: string, callback?: () => any) => async (dispatch: any) => {
  try {
    dispatch({ type: ActionTypes.REQUEST });

    await adminDeleteUser(id);

    dispatch({
      type: ActionTypes.SUCCESS,
      payload: null
    });

    Notify.success('User deleted successfully!');

    if (callback)
      callback();
  } catch (error) {
    dispatch({
      type: ActionTypes.FAILURE,
      payload: error
    });

    Notify.error(error, true);
  }
};
