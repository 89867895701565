import { IAdminUser } from "../User/type";

export const ActionTypes = {
  REQUEST: "ADMIN_FETCH_USERS_REQUEST",
  SUCCESS: "ADMIN_FETCH_USERS_SUCCESS",
  FAILURE: "ADMIN_FETCH_USERS_FAILURE",
}

export interface IAdminUsersState {
  data: IAdminUser[];
  loading: boolean;
}
