import {
  Empty,
  Spin,
  Tabs,
  TabsProps
} from "antd";
import { useDispatch, useSelector } from "react-redux";
// import { useLocation, useNavigate, useParams } from "react-router-dom";

// import ObjectForm from "components/Admin/ObjectForm";

// import { updateUser } from "Redux/Admin/GmUser/action";

import { RootState } from "Redux/store";
import UserInfo from "./UserInfo";
// import { ADMIN_PATH } from "utils/routes";

const GoalsManager = () => {
  // const { id } = useParams<{ id: string }>();

  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const { data, loading } = useSelector(
    (state: RootState) => state.fetchGmUserReducer
  );

  if (loading) {
    return <Spin tip="Loading" />;
  }

  if (!data) {
    return <Empty />;
  }

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'User Info',
      children: <UserInfo />,
    },
    {
      key: '2',
      label: 'Action Items',
      children: "Action Items",
      disabled: true
    }
  ];

  return (
    <Tabs
      defaultActiveKey="1"
      tabPosition={"top"}
      items={tabItems}
    />
  );
}

export default GoalsManager;
